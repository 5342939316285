import React from 'react'
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { TrainingSessionInterval } from '../TrainingSessionsTable/utils'

momentDurationFormatSetup(moment)

const TrainingSessionIntervals = ({ intervals }) => {
  const columns = React.useMemo<ColumnDef<TrainingSessionInterval>[]>(
    () => [
      {
        accessorKey: 'id',
        enableColumnFilter: true,
        header: () => 'ID',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'title',
        header: () => 'Title',
        footer: (props) => props.column.id,
      },
      {
        id: 'intervalNo',
        accessorKey: 'intervalNo',
        header: () => 'Interval No',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'goalTimeMs',
        header: () => 'Goal Time',
        cell: ({ row }) => {
          return row.original?.goalTimeMs
            ? moment.duration(row.original?.goalTimeMs)?.format('mm:ss')
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'goalDistanceMeters',
        header: () => 'Goal Distance (meters)',
        cell: ({ row }) => {
          return row.original?.goalDistanceMeters
            ? row.original?.goalDistanceMeters?.toLocaleString()
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'goalPaceMsPerKm',
        header: () => 'Goal Pace (per km)',
        cell: ({ row }) => {
          return row.original?.goalPaceMsPerKm
            ? moment.duration(row.original?.goalPaceMsPerKm)?.format('mm:ss')
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'goalPaceExpression',
        header: () => 'Goal Pace Expression',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'recordedTimeMs',
        header: () => 'Recorded Time (ms)',
        cell: ({ row }) => {
          return row.original?.recordedTimeMs
            ? moment.duration(row.original?.recordedTimeMs)?.format('mm:ss')
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'recordedDistanceMeters',
        header: () => 'Recorded Distance (meters)',
        cell: ({ row }) => {
          return row.original?.recordedDistanceMeters
            ? row.original?.recordedDistanceMeters?.toLocaleString()
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'recordedPaceMsPerKm',
        header: () => 'Recorded Pace (per km)',

        cell: ({ row }) => {
          return row.original?.recordedPaceMsPerKm
            ? moment
                .duration(row.original?.recordedPaceMsPerKm)
                ?.format('mm:ss')
            : 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'level',
        header: () => 'Level',
        cell: ({ row }) => {
          return row.original?.level || 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'reps',
        header: () => 'Reps',
        cell: ({ row }) => {
          return row.original?.reps || 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'url',
        header: () => 'Url',
        cell: ({ row }) => {
          return row.original?.url || 'N/A'
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'createdAt',
        header: () => 'Created At',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'updatedAt',
        header: () => 'Updated At',
        footer: (props) => props.column.id,
      },
    ],
    []
  )

  const table = useReactTable({
    data: intervals,
    columns,
    state: {
      pagination: {
        pageSize: 500,
        pageIndex: 0,
      },
    },
    initialState: {
      sorting: [
        {
          id: 'intervalNo',
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <table className="min-w-full">
      <thead className="bg-white">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="text-center"
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </div>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white">
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id} className="border-t border-gray-200">
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    key={cell.id}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-x border-gray-200 text-center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TrainingSessionIntervals
