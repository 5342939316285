import Roles from './Roles'

type User = {
  ability: string
  city: string
  createdAt: string
  updatedAt: string
  elevated: boolean
  email: string
  firstName: string
  id: number | string
  lastName: string
  reminderNotification: boolean
  subscription: boolean
  unitSystem: string
  role?: Roles
  avatar?: string
  trainingPlans: {
    plannedWeeks: number
    targetDate: string
    targetDistance: string
  }
  club: {
    name: string
    elevated: boolean
  }
  accountStatus: AccountStatus
}

export enum AccountStatus {
  subscribed = 'subscribed',
  donated = 'donated',
  limited = 'limited',
  default = 'default',
}

export default User
